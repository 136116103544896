<template>
  <transition name="modal">
    <div class="app-modal" v-if="isOpen" @click.self="onBackdropClicked">
        <div class="app-modal__container">
          <slot />
        </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeByBackClick: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    onBackdropClicked() {
      if (this.closeByBackClick) this.close();
    },
  },
};
</script>

<style lang="scss">
.app-modal {
  transition: opacity 0.3s ease;

  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 60px;

  @include media-bp(tab) {
    padding: 36px;
  }

  @include media-bp(mob) {
    padding: 16px;
  }

  &__container {
    background-color: $white;
    max-width: 100%;
    transition: all 0.3s ease;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .app-modal__container,
.modal-leave-active .app-modal__container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
