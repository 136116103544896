<template>
  <app-map id="map-contact" class="the-map-contact">
    <a class="btn the-map-contact__btn" @click="toggleForm" >{{
      $t("sections.map.button")
    }}</a>

    <div class="the-map-contact__form" v-if="isFormOpen">
      <div class="the-map-contact__form-close" @click="closeForm" />
      <div class="the-map-contact__form-header">
        {{ $t('sections.map.form.header') }}
      </div>

      <app-contact-form
        @sent="onSent"
        @error="onError"
      />
    </div>
  </app-map>
</template>

<script>
import AppMap from '@/components/app-map';
import AppContactForm from '@/components/app-contact-form';

export default {
  data() {
    return {
      isFormOpen: false,
    };
  },
  components: {
    AppMap,
    AppContactForm,
  },
  methods: {
    openForm() {
      this.isFormOpen = true;
    },
    closeForm() {
      this.isFormOpen = false;
    },
    toggleForm() {
      this.isFormOpen = !this.isFormOpen;
    },
    onSent() {
      this.closeForm();
    },
    onError(err) {
      console.error(err);
      alert('Something is wrong', err);
    },
  },
};
</script>

<style lang="scss">
.the-map-contact {
  &__btn {
    position: absolute;
    width: auto;
    bottom: 40px;
    right: 0;

    @include media-bp(tab) {
      font-size: 14px;
      min-height: 36px;
      bottom: 22px;
    }

    @include media-bp(mob) {
      font-size: 12px;
      line-height: 138.19%;
      min-height: 30px;
      bottom: 12px;
    }
  }

  &__form {
    position: absolute;
    bottom: 44px;
    right: 0;

    @include media-bp(tab) {
      bottom: 26px;
    }

    @include media-bp(mob) {
      bottom: 14px;
    }

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 31px;

    padding: 24px 20px;

    max-width: 374px;

    &-close {
      position: absolute;
      right: 12px;
      top: 12px;
      width: 28px;
      height: 28px;

      border: 1px solid #6f6a6a;
      border-radius: 50%;

      cursor: pointer;
      transition: all 0.3s ease;

      &::before {
        width: 1px;
        height: 50%;
      }
      &::after {
        height: 1px;
        width: 50%;
      }

      &::before,
      &::after {
        content: "";
        display: block;

        background-color: #6f6a6a;

        position: absolute;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:hover {
        transform: scale(1.2);
      }
    }

    &-header {
      font-weight: 600;
      font-size: 22px;
      line-height: 138.19%;
      color: #000000;

      text-align: center;

      margin-top: 16px;
      margin-bottom: 24px;
    }
  }
}
</style>
