<template>
  <div class="app-map qv-section qv-section--red">
    <div class="app-map__header  qv-section__header">
      {{ $t("sections.map.header") }}
    </div>
    <div class="app-map__body">
      <div class="app-map__map">
        <img :src="$t('sections.map.image')" alt="" />
      </div>

      <app-modal class="app-map__modal" ref="modal">
        <img :src="$t('sections.map.image')" alt="" />
      </app-modal>

      <div class="app-map__container qv-section__container">
        <div class="app-map__content">
          <img class="app-map__zoom" src="./img/zoom_btn.png" @click="openModal">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppModal from '@/components/app-modal';

export default {
  components: {
    AppModal,
  },
  methods: {
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
  },
};
</script>

<style lang="scss">
.app-map {
  &__header {
  }

  &__body {
    position: relative;
  }

  &__map {
    width: 100%;
    img {
      width: 100%;
      height: auto;
      filter: grayscale(80%)
    }
  }

  &__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }

  &__content {
    flex: 1 1 100%;
    position: relative;
  }

  &__zoom {
    position: absolute;
    right: 0;
    cursor: pointer;

    transform: translateY(32px);
    width: 80px;
    height: auto;

    @include media-bp(tab) {
      transform: translateY(20px);
      width: 60px;
    }

    @include media-bp(mob) {
      transform: translateY(8px);
      width: 32px;
    }
  }

  &__modal {
    img {
      max-width: 100%;
    }
  }
}
</style>
