<template>
  <div id="services" class="the-services qv-section" v-if="content?.services?.length">
    <div class="the-services__container qv-section__container">
      <div class="the-services__header qv-section__header">{{ content.header }}</div>
      <div class="the-services__list">
        <a
          v-for="(service, index) in content.services"
          :key="index"
          class="the-services__service"
          :href="service.url"
        >
          <div class="the-services__service-container">
            <img
              v-if="service.image"
              :src="service.image" alt=""
              class="the-services__service-image"
            >

            <div v-if="service.text" class="the-services__service-text">
              {{ service.text }}
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$t('sections.our-services');
    },
  },
};
</script>

<style lang="scss">
.the-services {
  padding-bottom: 36px;

  &__list {
    margin: 0 -16px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }

  &__service {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    padding: 0 16px;
    margin-bottom: 16px;

    &-container {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
    }

    &-image {
      width: 100%;
      object-fit: cover;
    }

    &-text {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      min-height: 67px;

      white-space: pre-line;

      padding: 8px 20px 10px;
      color: $white;
      font-weight: 600;
      font-size: 18px;
      line-height: 138.19%;

      background: radial-gradient(
        95% 95% at 49.03% 5%,
        rgba(255, 1, 1, 0.85) 0%,
        rgba(255, 94, 3, 0.65) 100%
      );
    }
  }

  @include media-bp(tab) {
    &__list {
      margin: 0 -9px;
    }

    &__service {
      padding: 0 9px;

      &-text {
        padding: 4px 10px;
        font-size: 12px;
        line-height: 1.137;
        min-height: 35px;
      }
    }
  }

  @include media-bp(mob) {
    &__service {
      &-container {
        display: flex;
      }
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
</style>
