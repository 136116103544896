<template>
  <div class="home">
    <the-slider />
    <the-services />
    <the-offers />
    <the-map-contact />
  </div>
</template>

<script>
import TheSlider from '@/components/the-slider';
import TheServices from '@/components/the-services';
import TheOffers from '@/components/the-offers';
import TheMapContact from '@/components/the-map-contact';

export default {
  components: {
    TheSlider,
    TheServices,
    TheOffers,
    TheMapContact,
  },
};
</script>

<style lang="scss">
</style>
