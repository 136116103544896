<template>
  <div id="slider" class="the-slider" v-if="content.slides.length">
    <div class="the-slider__background"></div>

    <carousel
      class="the-slider__carousel"
      :per-page="1"
      :pagination-position="'bottom-overlay'"
      pagination-active-color="#ff0000"
      pagination-color="#dea4a4"
      autoplay
      :autoplay-timeout="60000"
      :speed="1000"
      loop
    >
      <slide
        v-for="(slide, index) in content.slides"
        :key="index"
        class="the-slider__slide"
      >
        <div class="the-slider__slide-container">
          <div class="the-slider__slide-image">
            <img v-if="slide.image" :src="slide.image" alt="">
          </div>
          <div class="the-slider__slide-info">
            <div v-if="slide.header" class="the-slider__slide-header">
              {{ slide.header }}
            </div>
            <div v-if="slide.text" class="the-slider__slide-text">
              {{ slide.text }}
            </div>
            <a
              v-if="slide.button"
              class="btn the-slider__slide-btn"
              :href="slide.button.url"
            >
              {{ slide.button.text }}
            </a>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  data() {
    return {
    };
  },
  components: {
    Carousel,
    Slide,
  },
  computed: {
    content() {
      return this.$t('sections.top-slider');
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
.the-slider {
  padding-bottom: 50px;

  overflow: hidden;

  &__background {
    @include container;

    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 746px;
      height: 532px;
      right: 0;
      top: 0;
      background-color: $red;
      border-radius: 57px;
      z-index: -1;
      transform: translate(427px, -356px) rotate(-47.04deg);
    }
  }

  &__slide {
    position: relative;
    overflow: hidden;

    &-container {
      @include container;

      min-height: 530px;

      display: flex;
      flex-flow: row-reverse;
      align-items: stretch;
    }

    &-info {
      flex: 1 1 43%;
      max-width: 43%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &-header {
      color: #2d2929;
      font-size: 34px;
      line-height: 1.25;
      font-weight: 700;

      margin-bottom: 30px;
    }

    &-text {
      color: #2d2929;
      font-size: 24px;
      line-height: 1.25;
      font-weight: 400;

      margin-bottom: 30px;
    }

    &-image {
      flex: 1 1 57%;
      max-width: 57%;
      display: flex;
      align-items: flex-end;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }

  @include media-bp(tab) {
    &__background {
      &::before {
        transform: translate(324px, -500px) rotate(-47.04deg);
      }
    }

    &__slide {
      &-container {
        min-height: 275px;
      }

      &-info {
        margin-right: auto;
      }

      &-image {
        max-width: 350px;
      }

      &-header {
        font-size: 20px;
      }

      &-text {
        font-size: 16px;
      }
    }
  }

  @include media-bp(mob) {
    padding-bottom: 28px;

    &__background {
      &::before {
        transform: translate(318px, -500px) rotate(-47.04deg);
      }
    }

    &__slide {
      padding-bottom: 36px;

      &-container {
        flex-direction: column;
      }

      &-info {
        padding-top: 28px;
        flex: 1 1 auto;
        max-width: 100%;
      }

      &-image {
        max-width: 100%;
      }

      &-header {
        font-size: 24px;
        line-height: 125%;

        margin-bottom: 16px;
      }

      &-text {
        font-size: 18px;
        line-height: 125%;

        margin-bottom: 16px;
      }
    }
  }
}
</style>
